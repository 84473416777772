@import "../../../../libs/angular/src/scss/webfonts.css";
@import "./variables";
@import "../../../../libs/angular/src/scss/bwicons/styles/style.scss";
@import "../../../../libs/angular/src/scss/icons.scss";
@import "../../../../libs/components/src/multi-select/scss/bw.theme";
@import "@angular/cdk/overlay-prebuilt.css";

//@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/_mixins";
@import "~bootstrap/scss/_root";
@import "~bootstrap/scss/_reboot";
@import "~bootstrap/scss/_type";
@import "~bootstrap/scss/_images";
@import "~bootstrap/scss/_code";
@import "~bootstrap/scss/_grid";
@import "~bootstrap/scss/_tables";
@import "~bootstrap/scss/_forms";
@import "~bootstrap/scss/_buttons";
@import "~bootstrap/scss/_transitions";
@import "~bootstrap/scss/_dropdown";
// @import "~bootstrap/scss/_button-group";
@import "~bootstrap/scss/_input-group";
// @import "~bootstrap/scss/_custom-forms";
@import "~bootstrap/scss/_nav";
// @import "~bootstrap/scss/_navbar";
@import "~bootstrap/scss/_card";
// @import "~bootstrap/scss/_breadcrumb";
// @import "~bootstrap/scss/_pagination";
// @import "~bootstrap/scss/_jumbotron";
// @import "~bootstrap/scss/_alert";
@import "~bootstrap/scss/_progress";
// @import "~bootstrap/scss/_media";
@import "~bootstrap/scss/_list-group";
@import "~bootstrap/scss/_close";
//@import "~bootstrap/scss/_toasts";
@import "~bootstrap/scss/_modal";
// @import "~bootstrap/scss/_tooltip";
// @import "~bootstrap/scss/_popover";
// @import "~bootstrap/scss/_carousel";
// @import "~bootstrap/scss/_spinners";
@import "~bootstrap/scss/_utilities";
@import "~bootstrap/scss/_print";

@import "~ngx-toastr/toastr";

@import "./base";
@import "./buttons";
@import "./callouts";
@import "./cards";
@import "./forms";
@import "./modals";
@import "./pages";
@import "./plugins";
@import "./tables";
@import "./toasts";
@import "./vault-filters";

/**** START Vaultwarden CHANGES ****/
/* This combines all selectors extending it into one */
%vw-hide {
  display: none !important;
}

/* This allows searching for the combined style in the browsers dev-tools (look into the head tag) */
.vw-hide,
head {
  @extend %vw-hide;
}

/* Hide the Subscription Page tab */
bit-nav-item[route="settings/subscription"] {
  @extend %vw-hide;
}

/* Hide any link pointing to Free Bitwarden Families */
a[href$="/settings/sponsored-families"] {
  @extend %vw-hide;
}

/* Hide Two-Factor menu in Organization settings */
bit-nav-item[route="settings/two-factor"],
a[href$="/settings/two-factor"] {
  @extend %vw-hide;
}

/* Hide Business Owned checkbox */
app-org-info > form:nth-child(1) > div:nth-child(3) {
  @extend %vw-hide;
}

/* Hide the `This account is owned by a business` checkbox and label */
#ownedBusiness,
label[for^="ownedBusiness"] {
  @extend %vw-hide;
}

/* Hide the radio button and label for the `Custom` org user type */
#userTypeCustom,
label[for^="userTypeCustom"] {
  @extend %vw-hide;
}

/* Hide Business Name */
app-org-account form div bit-form-field.tw-block:nth-child(3) {
  @extend %vw-hide;
}

/* Hide organization plans */
app-organization-plans > form > h2.mt-5 {
  @extend %vw-hide;
}

/* Hide Device Verification form at the Two Step Login screen */
app-security > app-two-factor-setup > form {
  @extend %vw-hide;
}

/* Replace the Bitwarden Shield at the top left with a Vaultwarden icon */
.bwi-shield:before {
  content: "" !important;
  width: 32px !important;
  height: 40px !important;
  display: block !important;
  background-image: url(../images/icon-white.png) !important;
  background-repeat: no-repeat;
  background-position-y: bottom;
}
/**** END Vaultwarden CHANGES ****/
